import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TempImage from "../images/pop_placeholder.jpg";
import RawImage from "../images/raw-preview-1.jpg";
import cyborgCulturImage from "../images/cyborg-cultur-preview.png";
import tinyrainbootPreviewImage from "../images/preview-tinyrainboot.jpg"

const settingsCarousel = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '0px',
    arrows: true,
    className: 'center',
};

function scrollDown() {
    const scrollDownSection = document.getElementById('scrollDown');
    scrollDownSection.scrollIntoView({ behavior: 'smooth' });
}


const IndexPage = () => (

    <Layout>
        <Seo title="Home" />
        <div className='container clearfix'>
            <section className='home-intro'>
                <h1>Cyborg culture<br/><span>by Ira Greenberg</span><br /></h1>
                <a className='button white arrow' href="/collections/">Learn More</a>
            </section>
            <span className='home-intro-slogan'>Immutably Stored on the Solana Blockchain</span>
        </div>

        {/*<p>Go to Populus<Link to="/populus">Populus</Link>!</p>*/}

        <section className='how-it-works white' id='scrollDown'>
            <h3>How it works?</h3>
            <div className='container clearfix'>
                <div className='col33 left how-item'><h4>Connect your wallet</h4><p>Tap into the heart of our platform by hitting the 'connect wallet' button positioned at the top right. Remember, we're compatible with Phantom, Backpack, Solflare, and Glow. Dive in now!</p></div>
                <div className='col34 left how-item'><h4>Craft Your Masterpiece</h4><p>Within every drop lies a universe of artistry and unique outcomes. Take the leap and click on 'mint' when you're on a drop page to breathe life into your one-of-a-kind creation.</p></div>
                <div className='col33 left how-item'><h4>Champion Your Art</h4><p>Step into the vibrant world of our marketplace to showcase and monetize your minted masterpieces. All it takes is a quick click on your wallet dropdown, followed by selecting 'list for trade'. Make your mark today!</p></div>
            </div>
        </section>

        <div className='container clearfix collections'>
            <div className='section-title clearfix'>
                <div className='col50 left'><h2 className='no-margin'>Featured Collections</h2></div>
                <div className='col50 right'><Link to="/collections/" className='button arrow transparent'>View all collections</Link></div>
            </div>

            <Slider {...settingsCarousel} className='slider'>
            <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/cyborg-culture/">
                            <img src={cyborgCulturImage} alt="Cyborg culture" />
                        </a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>open</span>
                                <h2>Cyborg culture</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>222 to mint</a>
                            </div>
                        </div>
                        <p className='big'>Ira Greenberg introducing his latest project, titled "Cyborg Culture." This exploration delves into the concept of a cyborgian emergence, reflecting the evolving landscape shaped by advancing augmentation and digital mediation. The artwork seeks to capture this intersection of humanity and technology, presenting it with a sense of detached ambivalence. By steering clear of overt dystopian or utopian narratives, Ira aims to provoke thought and invite viewers to ponder the implications of our increasingly cyborgian reality.</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4> Ira Greenberg</div>
                            </div>
                        </div>
                        <Link className='button arrow transparent' to="/cyborg-culture/">View collection</Link>
                    </div>
                </div>
                <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/tinyrainboot/">
                            <img src={tinyrainbootPreviewImage} alt="The Contours of a Dream" />
                        </a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>open</span>
                                <h2>The Contours of a Dream</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>333 to mint</a>
                            </div>
                        </div>
                        <p className='big'>I awaken in a world that isn’t mine. Eerie and desolate, the weight of a loss I can’t quite remember washes over me. I thought I knew what time was, but it has lost its meaning in a dawn that stretches its fingers toward eternity. The air is different here: sharper, thinner, lighter. Can you imagine the sound of a planet where you are the only one breathing? I do not know if this is future or past, whether this place is forgotten or still unknown. My unspeakably fragile vessel of sinew and synapse is all that carries me forward. Have I been here before? Or am I simply tracing the contours of a dream?</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4>Tinyrainboot</div>
                            </div>
                        </div>
                        <Link className='button arrow transparent' to="/tinyrainboot/">View collection</Link>
                    </div>
                </div>
                <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/raw/">
                            <img src={RawImage} alt="RAW" />
                        </a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>closed</span>
                                <h2>RAW</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>73 minted</a>
                            </div>
                        </div>
                        <p className='big'>Stephan Vasement's first-ever long-form drop “RAW” blends horror and urban culture, capturing the tumultuous spirit of our times. This project uses animals as metaphors to mirror the chaotic state of the world and the emotional turmoil faced by today's youth. Vasement's work encapsulates the raw, visceral emotions and primal instincts that surface in response to contemporary challenges. Each piece in the collection portrays the current zeitgeist and offers a commentary on the collective human experience amid chaos.</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4>Stephan Vasement</div>
                            </div>
                        </div>
                        <Link className='button arrow transparent' to="/raw/">View collection</Link>
                    </div>
                </div>

                <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/populus/">
                            <img src={TempImage} />
                        </a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>closed</span>
                                <h2>Populus</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>150 minted</a>
                            </div>
                        </div>
                        <p className='big'>Populus imagines a future where humanity and technology have merged at the physical level. Born out of the question ‘What if evolution and annihilation are the same thing?’, Populus envisions a world where humanity has broken with its biological origins and found new ways to augment and amplify its material capacities. In this ‘new order’, much as in our current one, access to privilege and power is unequal, with unsettling ramifications for society as a whole.</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4><Link to='https://www.dreamcult.io/' target='_blank'>_DreamCult_</Link></div>
                            </div>
                            <div className='the-collection left'><h4>Collection</h4><Link to="/populus/">Populus by _DreamCult_</Link></div>
                        </div>
                        <Link className='button arrow transparent' to="/populus/">View collection</Link>
                    </div>
                </div>

                <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/waves-gallery/">
                            <video autoPlay="autoplay" muted={true} loop={true}
                                poster="https://d28a5q050a9bu1.cloudfront.net/images/waves/real-waves/999+.png">
                                <source src="https://d28a5q050a9bu1.cloudfront.net/videos/Wave-999-Video.mp4#t=9"
                                    type="video/mp4" />
                            </video>
                        </a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>closed</span>
                                <h2>Waves</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>1000 minted</a>
                            </div>
                        </div>
                        <p className='big'>Waves is a moving generative art collection created by Mccannatron. Waves is a collection of 1000 unique art pieces inspired by the fluidity of the crypto markets, including Bollinger Bands, wave theory, and moon math charts.</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4><a>@Mccannatron</a></div>
                            </div>
                            <div className='the-collection left'><h4>Collection</h4><Link to="/waves-gallery/">Waves by Mccannatron</Link></div>
                        </div>
                        <Link className='button arrow transparent' to="/waves-gallery/">View collection</Link>
                    </div>
                </div>

                <div className='clearfix'>
                    <div className='col48 left preview'>
                        <a href="/epochs-gallery/" className='crop'><video autoPlay="autoplay" muted={true} loop={true}
                            poster="https://d28a5q050a9bu1.cloudfront.net/images/epochs/0.png"><source src="https://d28a5q050a9bu1.cloudfront.net/videos/Epoch-Animation-home.mp4" type="video/mp4" /></video></a>
                    </div>
                    <div className='col48 right description'>
                        <div className='collection-title clearfix'>
                            <div className='col50 left'>
                                <span className='status'>closed</span>
                                <h2>Epochs</h2>
                            </div>
                            <div className='col50 right'>
                                <a className='button black right'>1000 minted</a>
                            </div>
                        </div>
                        <p className='big'>Inspired by the passage of time, Epochs is a collection of 1,000 unique randomly generated pieces of art stored on the Solana blockchain.</p>
                        <div className='collection-info clearfix'>
                            <div className='artist left clearfix'>
                                <div className='avatar left'></div>
                                <div className='artist-name left'><h4>Created by</h4><a>@Eko33</a></div>
                            </div>
                            <div className='the-collection left'><h4>Collection</h4><Link to="/epochs-gallery/">Epochs by Eko33</Link></div>
                        </div>
                        <Link to="/epochs-gallery/" className='button arrow transparent'>View collection</Link>
                    </div>
                </div>

            </Slider>

        </div>
    </Layout>
)

export default IndexPage
